import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RawData } from '../republic.service.type';
import { environment } from '../../../environment/environment';

@Injectable({
  providedIn: 'root',
})
export class ExtraPickupService {
  constructor(private http: HttpClient) {}

  getItemList(queryParam: object): Observable<RawData[]> {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem('Authorization') || '',
    });
    return this.http.post<RawData[]>(
      `${environment.end_point}/extra-pickup`,
      queryParam,
      { headers },
    );
  }
}
