<div class="search-form" *ngIf="accessToken && !isErrorRoute()">
  <form [formGroup]="searchForm" appClickOutside (clickOutside)="clickOutsideAutoComplete(addressList)">
  <!-- <form [formGroup]="searchForm"> -->
    <div class="flex" >
      <mat-form-field [style.width]="formWidth" >
        <mat-icon matPrefix class="address-prefix">place</mat-icon>
        <input data-cy="address-input" type="text" matInput  [matAutocomplete]="auto" formControlName="addressterm" class="address-background" placeholder="Type address...">
        <mat-icon matSuffix class="address-crossicon" data-cy="address-clear-button" (click)="clearAddress()">cancel</mat-icon>
         <mat-autocomplete #auto="matAutocomplete" [displayWith]="addressDisplayFn">
          <mat-option *ngFor="let suggestion of addressList; let i = index" [attr.data-cy]="'autocomplete-option-' + i" [value]="suggestion">
            {{ suggestion.display }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
  </div>
    <div class="flex">
      
      <mat-form-field [style.width]="formWidth">
        <mat-select formControlName="pickup" data-cy="pickup-option-dropdown" placeholder="Bulk Pickup" (selectionChange)="onPickupOptionChange($event)">
          <mat-option *ngFor="let option of pickupOptions" [attr.data-cy]="'pickup-option-' + option.value" [value]="option.value">{{ option.displayName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Conditionally show Date Picker when "B" is selected -->
  <div *ngIf="showDatePicker">
    <mat-form-field>
      <input matInput [matDatepicker]="picker" formControlName="date" [min]= "minDate" placeholder="Choose a date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>
  <div class="flex" *ngIf="showSecondDropdown">
      
    <mat-form-field [style.width]="formWidth">
      <mat-select formControlName="secondDropdown" placeholder="Container Type" (selectionChange)="onPickupOptionChange($event)">
        <mat-option *ngFor="let option of containerOptions" [value]="option.value">{{ option.displayName }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

    @if(selectionOption() === 'bulk-pickup' || selectionOption() === 'extra-pickup') { 
      <div class="flex" *ngIf="!showDatePicker">
        <mat-form-field class="example-chip-list" [style.width]="formWidth" >
          <mat-chip-grid #chipGrid aria-label="" formControlName="searchTerm" >
            @for (type of typeDetails(); track type) {
            <mat-chip-row (removed)="remove(type)" data-cy="selected-item" [editable]="true" (edited)="edit(type, $event.value)"
              [aria-description]="'press enter to edit ' + type.name">
              {{type.name}}
              <button matChipRemove data-cy="remove-item" [attr.aria-label]="'remove ' + type.name">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            }
            @if(selectionOption() === 'bulk-pickup') {
              <input data-cy="item-input" placeholder="List up to 5 items" id= "searchInput" [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" (keydown)="onKeyDown($event)" />
            } @else if(selectionOption() === 'extra-pickup') {
              <input data-cy="item-input" placeholder="Extra pickup LOBs" id= "searchInput" [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="false" (matChipInputTokenEnd)="add($event)" (keydown)="preventTyping($event)"/>
            }
          </mat-chip-grid>
        </mat-form-field>
      </div>
    }

  </form>
  <div class="item-list-container" style="width: 100%">
    @if(selectionOption() === 'extra-pickup') {
      <div *ngFor="let suggestion of extraPickupItems; let i = index">
        <div class="disableButton" data-cy="suggested-service" (click)="typeValue(suggestion, i)" [ngClass]="{'disabled': disabledItems[i]}">{{ suggestion }}</div>
      </div>
    } @else if(selectionOption() === 'bulk-pickup') {
      <div *ngFor="let suggestion of items; let i = index">
        <div class="disableButton" data-cy="suggested-item" (click)="typeValue(suggestion, i)" [ngClass]="{'disabled': disabledItems[i]}">{{ suggestion }}</div>
      </div>
    }
</div>
  <div class="buttons-bar">
    <button mat-raised-button [color]="!searchForm.valid ? '#dddfdd' : 'accent'" id= "searchButton" data-cy="search-button" [disabled]="!searchForm.valid" (click)="searchListSync(selectionOption())" class="search-button">
      <mat-icon>search</mat-icon>
      Search
    </button>
    <button mat-raised-button color="white" data-cy="clear-button" class="clear-button" (click)="clearSearch()">
      Clear Search
    </button>
</div>
<div class="spinner-container">
  <mat-progress-spinner
    *ngIf="isLoading"
    color="primary"
    class="custom-spinner"
    mode="indeterminate">
  </mat-progress-spinner>
</div>
  <div *ngIf="transformedData.searchResults && !mpuData">
    <div *ngFor="let item of getItemNames()" data-cy="result-block" class="result-block">
      <div class="reaction-box">
        <span class="mt-icon material-icons-round mat-icon-like" [attr.data-cy]="'thumb-up-' + item" [ngClass]=" {
          'feed-back-success' : feedBackStatus[item] && feedBackStatus[item]['isFeedBack'] === 'true',
          'disabled':  feedBackStatus[item] && feedBackStatus[item]['isFeedBack'] === 'true'} "
          (click)="feedBack('true', item)" >
          thumb_up
        </span>
        <span class="mt-icon material-icons-round mat-icon-dislike" [attr.data-cy]="'thumb-down-' + item" [ngClass]=" {
          'feed-back-faliure' : feedBackStatus[item] && feedBackStatus[item]['isFeedBack'] === 'false',
          'disabled' : feedBackStatus[item] && feedBackStatus[item]['isFeedBack'] === 'false'} " (click)="feedBack('false', item)">
          thumb_down
        </span>
      </div>
      <h3 class="search-input" data-cy="result-block-header">{{ item }}</h3>
      <div *ngFor="let resultItem of transformedData.searchResults[item]">
          <div *ngFor="let key of getFieldKeys(resultItem)" class="result-conditional-box">
            
            <!-- Pull out acceptable for bulk or is available for XPU -->
            <div *ngIf="key == 'is_acceptable'">
              <mat-icon *ngIf="getAcceptanceStatus(resultItem[getFieldKeys(resultItem)[0]].Generated_Response) === 'Acceptable'" class="mat-icon-label" data-cy="icon-result-outcome">check_circle_rounded</mat-icon>
              <mat-icon *ngIf="getAcceptanceStatus(resultItem[getFieldKeys(resultItem)[0]].Generated_Response) === 'Not Acceptable'" class="mat-icon-dnd" data-cy="icon-result-outcome">do_not_disturb</mat-icon>
              <mat-icon *ngIf="getAcceptanceStatus(resultItem[getFieldKeys(resultItem)[0]].Generated_Response) === 'Conditional'" class="mat-icon-warning" data-cy="icon-result-outcome">warning</mat-icon>
                <span class="result-outcome" data-cy="result-outcome">{{ getAcceptanceStatus(resultItem[getFieldKeys(resultItem)[0]].Generated_Response) }}</span>
              </div>

            <!-- <mat-icon *ngIf="key === 'conditional'" class="mat-icon-warning">warning</mat-icon> -->

            <span class="result-heading" [attr.data-cy]="'result-heading-' + key" *ngIf="key !=='is_acceptable'">
              <img *ngIf="getDisplayName(key) === 'Size/Weight Restrictions'" class="mat-icon-results" [attr.data-cy]="'icon-' + key" src="{{iconUrl}}size-weight-restrictions.png">
              <img *ngIf="getDisplayName(key) === 'Service Limit'" class="mat-icon-results" [attr.data-cy]="'icon-' + key" src="{{iconUrl}}service-limit.png">
              <img *ngIf="getDisplayName(key) === 'Service Frequency'" class="mat-icon-results" [attr.data-cy]="'icon-' + key" src="{{iconUrl}}service-frequency.png">
              <img *ngIf="getDisplayName(key) === 'Advance Scheduling'" class="mat-icon-results" [attr.data-cy]="'icon-' + key" src="{{iconUrl}}advanced-scheduleing.png">
              <img *ngIf="getDisplayName(key) === 'Pre-payment'" class="mat-icon-results" [attr.data-cy]="'icon-' + key" src="{{iconUrl}}pre-payment.png">
              <img *ngIf="getDisplayName(key) === 'Preparation'" class="mat-icon-results" [attr.data-cy]="'icon-' + key" src="{{iconUrl}}preparation.png">
              <img *ngIf="getDisplayName(key) === 'Rates'" class="mat-icon-results" [attr.data-cy]="'icon-' + key" src="{{iconUrl}}rates.png">
              <img *ngIf="getDisplayName(key) === 'Included Service'" class="mat-icon-results" [attr.data-cy]="'icon-' + key" src="{{iconUrl}}preparation.png" style="width: 34px;">
              <img *ngIf="getDisplayName(key) === 'Extra Pickup Available'" class="mat-icon-results" [attr.data-cy]="'icon-' + key" src="{{iconUrl}}extra-available.png">
              {{ getDisplayName(key) }}
            </span>
              <span class="generated_response" [attr.data-cy]="'result-' + key">{{ getBeforeLastComma(resultItem[key].Generated_Response) }}</span>
          </div>
        </div>
    </div>
  </div>

  <div *ngIf="serviceChangeData" data-cy="service-change-result-block" class="result-block">
    <h3 class="search-input adjacent-div" data-cy="service-change-result-block-header">Service Change Info</h3>
    <div class="reaction-box">
      <span class="mt-icon material-icons-round mat-icon-like thumbs-up" data-cy="service-change-thumb-up" [class.selected]="selectedThumb === 'up'"
        (click)="serviceChangeFeedBack('true', 'SC')" >
        thumb_up
      </span>
      <span class="mt-icon material-icons-round mat-icon-dislike thumbs-down" data-cy="service-change-thumb-down" [class.selected]="selectedThumb === 'down'" (click)="serviceChangeFeedBack('false', 'SC')">
        thumb_down
      </span>
    </div>
    <div style="float: left;">
      <p data-cy="service-change-response">
        <ng-container *ngFor="let line of serviceChangeData.body.llmResponse[0].Answer.split('\n'); let last = last">
          {{line}}<br *ngIf="!last">
        </ng-container>
      </p>
    </div>
  </div>

  <div *ngIf="mpuData" class="result-block">
    <h3 class="search-input adjacent-div" data-cy="result-block-header">Missed Pickup Details</h3>
    <div class="reaction-box">
      <span class="mt-icon material-icons-round mat-icon-like thumbs-up" [class.selected]="selectedThumb === 'up'"
        (click)="mpuFeedBack('true', 'MPU')" >
        thumb_up
      </span>
      <span class="mt-icon material-icons-round mat-icon-dislike thumbs-down" [class.selected]="selectedThumb === 'down'" (click)="mpuFeedBack('false', 'MPU')">
        thumb_down
      </span>
    </div>
    <p *ngIf="invalidFlag" style="float: left;">
      {{ invalidFlag }}
    </p>
  </div>
  <div *ngIf="mpuData" class="result-block">
    <h4 class="search-input adjacent-div" data-cy="result-block-header">Supporting Data</h4>

    <div style="float: left;">
      <p>
        <strong>Address:</strong> {{ mpuData.body.agentAnswer.siteAddress }}
      </p>
      <p>
        <strong>Waste Type:</strong> {{ mpuData.body.agentAnswer.wasteType }}
      </p>
      <p>
        <strong>Missed Pickup Date:</strong> {{ mpuData.body.agentAnswer.missedPickupDate }}
      </p>
      <p>
        <strong>Account ID:</strong> {{ mpuData.body.agentAnswer.accountId }}
      </p>
      <p>
        <strong>Site ID:</strong> {{ mpuData.body.agentAnswer.siteId }}
      </p>
      <p>
        <strong>Container ID:</strong> {{ mpuData.body.agentAnswer.containerId }}
      </p>
      <p>
        <strong>Division ID:</strong> {{ mpuData.body.agentAnswer.divisionId }}
      </p>
      <p>
        <strong>Is Account Active:</strong> {{ mpuData.body.agentAnswer.isAccountActive  ? "Yes" : "No" }}
      </p>
      <p>
        <strong>Active Containers on Account:</strong> {{ mpuData.body.agentAnswer.activeContainersOnAccount  ? "Yes" : "No"}}
      </p>
      <p>
        <strong>Is Container on Pickup Schedule:</strong> {{ mpuData.body.agentAnswer.isContainerOnPickUpSchedule  ? "Yes" : "No"}}
      </p>
      <p>
        <strong>Is Container Blocked:</strong> {{ mpuData.body.agentAnswer.isContainerBlocked  ? "Yes" : "No"}}
      </p>
      <p>
        <strong>Is Container Contaminated:</strong> {{ mpuData.body.agentAnswer.isContainerContaminated  ? "Yes" : "No"}}
      </p>
      <p>
        <strong>Is Container Overfilled:</strong> {{ mpuData.body.agentAnswer.isContainerOverfilled  ? "Yes" : "No"}}
      </p>
      <p>
        <strong>Is Container Locked:</strong> {{ mpuData.body.agentAnswer.isContainerLocked  ? "Yes" : "No"}}
      </p>
      <p>
        <strong>Is Container Not Placed Out:</strong> {{ mpuData.body.agentAnswer.isContainerNotPlacedOut  ? "Yes" : "No"}}
      </p>
      <!-- <p>
        <strong>Case Number:</strong> {{ mpuData.body.agentAnswer.caseNumber }}
      </p> -->
      <p>
        <strong>Response Summary:</strong> {{ mpuData.body.agentAnswer.responseSummary }}
      </p>
      
    </div>
    
  </div>
  
  
</div>
<ng-template #loginMessage>
  <!-- <div class="search-form">Please login....</div> -->
</ng-template>

<ng-template #loading>
  <p>Loading...</p>
</ng-template>
<div *ngIf="isErrorRoute()">
<router-outlet></router-outlet>
</div>