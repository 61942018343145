import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ErrorComponentComponent } from './error-component/error-component.component';

export const routes: Routes = [{
  path: '',
  component: AppComponent,
},
{
  path: 'error',
  component: ErrorComponentComponent,
},
{ path: '**', redirectTo: '/error' }];
