import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environment/environment';
import {
  AddresResponse, FeedBack, TransformedData, RawData,
  Root, ResultEntry,
} from './republic.service.type';

@Injectable({
  providedIn: 'root',
})
export class ApiServicesService {
  baser_url: string = '';

  end_point: string = '';

  fileUrl = 'assets/sample.json';

  fullAddress: string;

  dummyProperty: string = '';

  private lastAddress: string | null = null;

  constructor(private http: HttpClient) {
    this.baser_url = environment.api_url;
    this.end_point = environment.end_point;
    this.fullAddress = '';
  }

  getSearch(query: string): Observable<string> {
    return this.http.get<string>(`${this.baser_url}?search=${query}`);
  }

  getJSON(): Observable<Root> {
    return this.http.get<Root>(this.fileUrl);
  }

  setFeedback(queryParam: FeedBack): Observable<FeedBack[]> {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem('Authorization') || '',
    });
    return this.http.post<FeedBack[]>(`${this.end_point}/feedback`, queryParam, { headers });
  }

  getAddressList(query: string): Observable<AddresResponse[]> {
    return this.http.get<AddresResponse[]>(`${this.baser_url}/addresses/auto?includeFullZipCode=false&addressLine1=${query}`);
  }

  getItemList(queryParam: object): Observable<RawData[]> {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem('Authorization') || '',
    });
    return this.http.post<RawData[]>(`${this.end_point}/item-search`, queryParam, { headers });
  }

  transformData(rawData: RawData): Observable<TransformedData> {
    const result: TransformedData = { searchResults: {} };
    this.dummyProperty = 'test';
    // Iterate through each key in rawData.data
    Object.keys(rawData.data).forEach((itemKey) => {
      const itemData = rawData.data[itemKey];

      // temp fix for LLM team defect
      if (itemData?.ParallelResults) {
        itemData.ParallelResult = itemData.ParallelResults;
      }

      if (!itemData || !Array.isArray(itemData.ParallelResult)) {
        return; // Skip if itemData is invalid
      }

      result.searchResults[itemKey] = []; // Initialize an empty array for searchResults

      // Flag to track if we should only show the first entry
      let showFirstEntryOnly = false;
      let firstEntry: ResultEntry | null = null;

      // Process each entry in ParrelelResult
      itemData.ParallelResult.forEach((entry) => {
        const key = Object.keys(entry)[0]; // Assuming there's only one key per entry
        const generatedResponse = entry[key]?.Generated_Response; // Safely get the response
        // Check if the generatedResponse is not acceptable
        if (generatedResponse && (generatedResponse.trim().split('.')[0].toLowerCase() === 'not acceptable'
        || generatedResponse.trim().split('.')[0].toLowerCase() === 'not-acceptable')
        ) {
          if (!firstEntry) {
            firstEntry = { [key]: { Generated_Response: generatedResponse } };
          }
          showFirstEntryOnly = true; // Mark to show only the first entry
        } else if (!showFirstEntryOnly) {
          // If the response is acceptable, push it into results
          result.searchResults[itemKey].push({
            [key]: {
              Generated_Response: generatedResponse,
            },
          });
        }
      });

      // If we need to show only the first entry, add it to searchResults
      if (showFirstEntryOnly && firstEntry) {
        result.searchResults[itemKey] = [firstEntry]; // Replace with first entry
      }
    });

    // Return the result wrapped in an Observable
    return of(result); // Ensure that we always return the result
  }

  setAddress(newAddress: string): boolean {
    const isDifferent = this.lastAddress !== newAddress;
    this.lastAddress = newAddress;
    return isDifferent;
  }
}
