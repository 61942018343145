<div class="search-form" *ngIf="accessToken && !isErrorRoute()">
  <form [formGroup]="searchForm" appClickOutside (clickOutside)="clickOutsideAutoComplete(addressList)">
    <div class="flex" >
      <mat-form-field [style.width]="formWidth" >
        <mat-icon matPrefix class="address-prefix">place</mat-icon>
        <input data-cy="address-input" type="text" matInput  [matAutocomplete]="auto" formControlName="addressterm" class="address-background" placeholder="Type address...">
        <mat-icon matSuffix class="address-crossicon" data-cy="address-clear-button" (click)="clearAddress()">cancel</mat-icon>
         <mat-autocomplete #auto="matAutocomplete" [displayWith]="addressDisplayFn">
          <mat-option *ngFor="let suggestion of addressList; let i = index" [attr.data-cy]="'autocomplete-option-' + i" [value]="suggestion">
            {{ suggestion.display }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
  </div>
    <div class="flex">
      
      <mat-form-field [style.width]="formWidth">
        <mat-select formControlName="pickup" data-cy="pickup-option-dropdown" placeholder="Bulk Pickup" (selectionChange)="onPickupOptionChange($event)">
          <mat-option *ngFor="let option of pickupOptions" [attr.data-cy]="'pickup-option-' + option.value" [value]="option.value">{{ option.displayName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

     
    <div class="flex">
      <mat-form-field class="example-chip-list" [style.width]="formWidth" >
        <mat-chip-grid #chipGrid aria-label="" formControlName="searchTerm" >
          @for (type of typeDetails(); track type) {
          <mat-chip-row (removed)="remove(type)" data-cy="selected-item" [editable]="true" (edited)="edit(type, $event.value)"
            [aria-description]="'press enter to edit ' + type.name">
            {{type.name}}
            <button matChipRemove data-cy="remove-item" [attr.aria-label]="'remove ' + type.name">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          }
          @if(selectionOption() === 'bulk-pickup') {
            <input data-cy="item-input" placeholder="List up to 5 items" id= "searchInput" [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" (keydown)="onKeyDown($event)" />
          } @else if(selectionOption() === 'extra-pickup') {
            <input data-cy="item-input" placeholder="Extra pickup LOBs" id= "searchInput" [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="false" (matChipInputTokenEnd)="add($event)" (keydown)="preventTyping($event)"/>
          }
        </mat-chip-grid>
      </mat-form-field>
    </div>

  </form>
  <div class="item-list-container" style="width: 100%">
    @if(selectionOption() === 'extra-pickup') {
      <div *ngFor="let suggestion of extraPickupItems; let i = index">
        <div class="disableButton" (click)="typeValue(suggestion, i)" [ngClass]="{'disabled': disabledItems[i]}">{{ suggestion }}</div>
      </div>
    } @else if(selectionOption() === 'bulk-pickup') {
      <div *ngFor="let suggestion of items; let i = index">
        <div class="disableButton" data-cy="suggested-item" (click)="typeValue(suggestion, i)" [ngClass]="{'disabled': disabledItems[i]}">{{ suggestion }}</div>
      </div>
    }
</div>
  <div class="buttons-bar">
    <button mat-raised-button [color]="!searchForm.valid ? '#dddfdd' : 'accent'" id= "searchButton" data-cy="search-button" [disabled]="!searchForm.valid" (click)="searchListSync(selectionOption())" class="search-button">
      <mat-icon>search</mat-icon>
      Search
    </button>
    <button mat-raised-button color="white" data-cy="clear-button" class="clear-button" (click)="clearSearch()">
      Clear Search
    </button>
</div>
<div class="spinner-container">
  <mat-progress-spinner
    *ngIf="isLoading"
    color="primary"
    class="custom-spinner"
    mode="indeterminate">
  </mat-progress-spinner>
</div>
  <div *ngIf="transformedData.searchResults">
    <div *ngFor="let item of getItemNames()" data-cy="result-block" class="result-block">
      <div class="reaction-box">
        <span class="mt-icon material-icons-round mat-icon-like" [ngClass]=" {
          'feed-back-success' : feedBackStatus[item] && feedBackStatus[item]['isFeedBack'] === 'true',
          'disabled':  feedBackStatus[item] && feedBackStatus[item]['isFeedBack'] === 'true'} "
          (click)="feedBack('true', item)" >
          thumb_up
        </span>
        <span class="mt-icon material-icons-round mat-icon-dislike" [ngClass]=" {
          'feed-back-faliure' : feedBackStatus[item] && feedBackStatus[item]['isFeedBack'] === 'false',
          'disabled' : feedBackStatus[item] && feedBackStatus[item]['isFeedBack'] === 'false'} " (click)="feedBack('false', item)">
          thumb_down
        </span>
      </div>
      <h3 class="search-input" data-cy="result-block-header">{{ item }}</h3>
      <div *ngFor="let resultItem of transformedData.searchResults[item]">
          <div *ngFor="let key of getFieldKeys(resultItem)" class="result-conditional-box">
            
            <!-- Pull out acceptable for bulk or is available for XPU -->
            <div *ngIf="isFirstItem(resultItem)">

              <mat-icon *ngIf="getFirstItemStatus(resultItem).status === 'Acceptable'" class="mat-icon-label">check_circle_rounded</mat-icon>
              <mat-icon *ngIf="getFirstItemStatus(resultItem).status === 'Not Acceptable'" class="mat-icon-dnd">do_not_disturb</mat-icon>
              <mat-icon *ngIf="getFirstItemStatus(resultItem).status === 'Conditional'" class="mat-icon-warning">warning</mat-icon>
              <span class="result-outcome">{{ getFirstItemStatus(resultItem).Generated_Response }}</span>
            
            </div>
            
            <!-- <mat-icon *ngIf="key === 'conditional'" class="mat-icon-warning">warning</mat-icon> -->

            @if(key !== 'is_acceptable' && key !== 'is_extra_pickup_available') {
              <span class="result-heading">
                <img *ngIf="getDisplayName(key) === 'Size/Weight Restrictions'" class="mat-icon-results" src="{{iconUrl}}size-weight-restrictions.png">
                <img *ngIf="getDisplayName(key) === 'Service Limit'" class="mat-icon-results" src="{{iconUrl}}service-limit.png">
                <img *ngIf="getDisplayName(key) === 'Service Frequency'" class="mat-icon-results" src="{{iconUrl}}service-frequency.png">
                <img *ngIf="getDisplayName(key) === 'Advance Scheduling'" class="mat-icon-results" src="{{iconUrl}}advanced-scheduleing.png">
                <img *ngIf="getDisplayName(key) === 'Pre-payment'" class="mat-icon-results" src="{{iconUrl}}pre-payment.png">
                <img *ngIf="getDisplayName(key) === 'Preparation'" class="mat-icon-results" src="{{iconUrl}}preperation.png">
                <img *ngIf="getDisplayName(key) === 'Rates'" class="mat-icon-results" src="{{iconUrl}}rates.png">{{ getDisplayName(key) }}</span>
                <img *ngIf="getDisplayName(key) === 'Included Service'" class="mat-icon-results" src="{{iconUrl}}service-limit.png">
                <span class="generated_response" [attr.data-cy]="key">{{ getBeforeLastComma(resultItem[key].Generated_Response) }}</span>
            }
            
          </div>
        </div>
    </div>
  </div>
  
</div>
<ng-template #loginMessage>
  <!-- <div class="search-form">Please login....</div> -->
</ng-template>

<ng-template #loading>
  <p>Loading...</p>
</ng-template>
<div *ngIf="isErrorRoute()">
<router-outlet></router-outlet>
</div>