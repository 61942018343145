export const environment = {
  production: false,
  api_url: 'https://www.republicservices.com/api/v1',
  end_point: 'https://alb.aiservicesdev.awsext.repsrv.com/api/v1',
  json_url: './assets/',
  authority: 'https://login.microsoftonline.com/eeb53a15-a2e4-46de-8608-22657ab58979',
  clientId: '1f85072c-d63d-4cf3-9adc-5c5999fcfbf6',
  redirectUri: 'https://kmai.aiservicesdev.awsext.repsrv.com',
  icon_url: './assets/icons/',
};
