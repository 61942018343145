import { ApplicationConfig } from '@angular/core';
import {
  PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading,
} from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient } from '@angular/common/http';
import { Configuration, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MSAL_INSTANCE, MsalService } from '@azure/msal-angular';
import { provideToastr } from 'ngx-toastr';
import { environment } from '../environment/environment';
import { routes } from './app.routes';

export const msalConfig: Configuration = {
  auth: {
    authority: environment.authority,
    clientId: environment.clientId,
    redirectUri: environment.redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};
export const msalInstance: IPublicClientApplication = new PublicClientApplication(msalConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(routes, withPreloading(PreloadAllModules), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),
    provideHttpClient(),
    { provide: MSAL_INSTANCE, useFactory: () => msalInstance },
    MsalService,
    provideToastr(), // Toastr providers
  ],
};
