import {
  Component, ElementRef, HostListener, inject, OnInit, signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import {
  ActivatedRoute, RouterModule, RouterOutlet, Router,
} from '@angular/router';
import {
  FormBuilder, FormControl, FormGroup, Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule, AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map, retry, startWith, switchMap, takeUntil,
} from 'rxjs/operators';
import { AuthenticationResult } from '@azure/msal-browser';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatIconModule } from '@angular/material/icon';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { DefaultGlobalConfig } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ApiServicesService } from './services/api-services.service';
import { environment } from '../environment/environment';
import {
  AddresResponse, FormData, BulkPickResultConditional, TransformedData,
  RawData,
  AddresResponseResult,
  ParallelResultItem,
  Daum,
  FeedBackStatus,
} from './services/republic.service.type';
import { NgxToastrService } from './services/ngx-toastr.service';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ExtraPickupService } from './services/extra-pickup/extra-pickup.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatButtonModule,
    AsyncPipe,
    CommonModule,
    MatAutocompleteTrigger,
    MatChipsModule,
    RouterModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ClickOutsideDirective,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})

export class AppComponent implements OnInit {
  @ViewChild('searchButton', { static: false }) searchButton!: ElementRef;

  title = 'aip-km-frontend';

  searchForm: FormGroup;

  unsubscribeAll: Subject<string> = new Subject<string>();

  pickupOptions = [
    { displayName: 'Bulk Pickup', value: 'bulk-pickup' },
    { displayName: 'Extra Pickup', value: 'extra-pickup' },
  ];

  filteredOptions: Observable<string[]>;

  redirect: boolean;

  items: string[] = ['Mattress', 'Microwave', 'Refrigerator', 'Table', 'Couch', 'Chair', 'BBQ Grill', 'Pallet', 'Water Heater', 'Box Spring'];

  extraPickupItems: string[] = ['Trash', 'Recycle', 'Yard Waste'];

  dummyProperty = '';

  addressOptions: Observable<AddresResponse[]>;

  transformedData: TransformedData = { searchResults: {} };

  formWidth: string;

  key: string = 'is_acceptable';

  topLevelItems: string[] = ['is_extra_pickup_available', 'is_acceptable'];

  disabledItems: boolean[] = new Array(this.items.length).fill(false);

  readonly addOnBlur = true;

  llmReqId!: string;

  createdOn!: string;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  typeDetails = signal<FormData[]>([]);

  readonly announcer = inject(LiveAnnouncer);

  bulkPickResultConditional: BulkPickResultConditional[];

  jsonUrl: string = '';

  addressList!: Daum[];

  accessToken!: AuthenticationResult;

  address: string;

  feedBackStatus!: FeedBackStatus;

  dynamicKeys: string[] = [];

  isLoading: boolean = false;

  initialFormValue: FormData[];

  iconUrl: string;

  selectionOption: WritableSignal<'bulk-pickup' | 'extra-pickup'> = signal('bulk-pickup');

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.formWidth = '100%';
  }

  constructor(
    private formBuilder: FormBuilder,
    private api_service: ApiServicesService,
    private msalService: MsalService,
    private http: HttpClient,
    private ngxToastrService: NgxToastrService,
    private activedRoute: ActivatedRoute,
    private router: Router,
    private extraPickupService: ExtraPickupService,
  ) {
    this.searchForm = this.formBuilder.group({
      pickup: new FormControl('bulk-pickup', Validators.required),
      searchTerm: new FormControl('', Validators.required),
      addressterm: new FormControl('', Validators.required),
    });
    this.iconUrl = environment.icon_url;
    this.formWidth = '100%';
    this.redirect = true;
    this.filteredOptions = this.searchForm.get('searchTerm')!.valueChanges.pipe(
      startWith(''),
      map(() => this.filterResults()),
    );
    this.bulkPickResultConditional = [];
    // Initialize addressOptions with the Observable
    this.addressOptions = this.searchForm.get('addressterm')!.valueChanges.pipe(
      debounceTime(300),
      filter((value: string) => value.length > 3),
      switchMap((value) => this.addressSearch(value).pipe(
        retry(1), // Retry up to 3 times before failing
        catchError(() => of()),
      )),
    );
    this.initialFormValue = this.typeDetails();
    this.initializeAddressOptions();
    this.address = '';
  }

  // Subscribe to addressOptions where you need to handle the emitted values
  initializeAddressOptions() {
    // Subscribe to addressOptions where you need to handle the emitted values
    this.addressOptions.pipe(
      takeUntil(this.unsubscribeAll),
    ).subscribe((response) => {
      const res = response as unknown as AddresResponseResult;
      const seen = new Set();
      const uniqueue = res.data.filter((item) => {
        const duplicate = seen.has(item.display);
        seen.add(item.display);
        return !duplicate;
      });
      this.addressList = uniqueue;
    }, (error) => {
      console.log(error);
    });
  }

  clickOutsideAutoComplete(addressList: AddresResponse[]) {
    const addressTermValue = this.searchForm.get('addressterm')?.value;
    if (addressList && addressList.length > 0 && !addressTermValue?.display) {
      this.searchForm.patchValue({
        addressterm: addressList[0],
      });
    }
  }

  async ngOnInit(): Promise<void> {
    this.activedRoute.queryParams.pipe(debounceTime(1000))
      .subscribe((param) => {
        if (param['address']) {
          this.searchForm.patchValue({
            addressterm: param['address'],
          });
        }
        if (param['AutomationAccessToken']) {
          const token = param['AutomationAccessToken'];
          if (token) {
            const payloadPart = token.split('.')[1];
            try {
              localStorage.setItem('Authorization', token);
              const decodePayload = atob(payloadPart);
              const accessToken = JSON.parse(decodePayload);
              this.accessToken = accessToken.automation_auth;
            } catch (error) {
              console.log(error);
            }
          }
        } else {
          this.getAccessToken();
        }
      });
    this.redirect = true;
    this.formWidth = '100%';
  }

  async getAccessToken() {
    await this.msalService.instance.initialize();
    await this.msalService.instance.handleRedirectPromise();
    const request = {
      scopes: ['profile'],
      // loginHint: accounts[0].username,
    };
    try {
      const accessToken = await this.msalService.instance.ssoSilent(request);
      console.log('accessToken', accessToken);
      if (accessToken) {
        this.accessToken = accessToken;
        localStorage.setItem('Authorization', accessToken.idToken);
      }
      const accounts = await this.msalService.instance.getAllAccounts();
      console.log('Accounts Details', accounts);
    } catch {
      await this.msalService.loginPopup().subscribe((token) => {
        if (token) {
          this.accessToken = token;
          localStorage.setItem('Authorization', token.idToken);
        }
      }, (error) => { console.log(error); });
    }
    this.jsonUrl = environment.json_url;
  }

  onPickupOptionChange(event: any) {
    const selectedOption = event.value;
    this.selectionOption.set(selectedOption);
    this.typeDetails.update(() => []);
    if (selectedOption === 'bulk-pickup') {
      this.disabledItems = new Array(this.items.length).fill(false);
    } else if (selectedOption === 'extra-pickup') {
      this.disabledItems = new Array(this.extraPickupItems.length).fill(false);
    }
  }

  // async checkLink(url: string) {
  //   console.log('Checking link:', url); // Debugging log

  //   try {
  //     const response = await fetch(url, { method: 'GET', mode: 'no-cors' });
  //     if (response.status == 200 || response.status == 304) {
  //       this.dummyProperty = 'test';
  //     } else {
  //       this.redirect = false;
  //       this.router.navigate(['/error']);
  //     }
  //   } catch (error) {
  //     this.router.navigate(['/error']);
  //   }
  // }

  addressSearch(query: string) {
    return this.api_service.getAddressList(query);
  }

  onKeyDown(event: KeyboardEvent) {
    this.dummyProperty = 'test';
    if (event.key === 'Tab') {
      event.preventDefault(); // Prevent the default tab behavior
      // Ensure the button reference is available and set focus
      const buttonElement = document.getElementById('searchButton') as HTMLButtonElement;
      if (buttonElement) {
        buttonElement.focus();
        console.log('Search button found.');
      }
    }
  }

  static getKeys(obj: string): string[] {
    return obj ? Object.keys(obj) : []; // Safely get keys only if obj is defined
  }

  typeValue(value: string, index: number): void {
    this.disabledItems[index] = true;
    this.typeDetails.update((type) => [...type, { name: value }]);

    if (this.typeDetails().length > 5) {
      this.remove(this.typeDetails()[0]);
    }
    this.searchForm.patchValue({
      searchTerm: this.typeDetails(),
    });
  }

  openToastrShow(message: string) {
    const successIconClass = DefaultGlobalConfig?.iconClasses?.error || '';
    this.ngxToastrService.show(
      message,
      successIconClass,
    );
  }

  searchListSync(itemType: 'bulk-pickup' | 'extra-pickup'): void {
    this.isLoading = true;
    if (itemType === 'bulk-pickup') {
      this.searchList();
    } else if (itemType === 'extra-pickup') {
      this.searchExtraPickup();
    }
  }

  parseAddressTerm(raw: string) {
    this.dummyProperty = 'test';
    // Regular expression to match the expected address format
    const regex = /^(.+),\s*(.+),\s*([A-Z]{2}),\s*(\d{5})$/;
    const match = raw?.match(regex);

    if (match) {
      return {
        addressLine1: match[1].trim(),
        addressLine2: '',
        city: match[2].trim(),
        country: 'USA',
        display: raw.trim(),
        postalCode: match[4].trim(),
        stateCode: match[3].trim(),
      };
    }

    return null; // Return null if the format is incorrect
  }

  addressDisplayFn(address: AddresResponse): string {
    return address && address.display ? address.display : '';
  }

  prepareDataForSearch(): any {
    const addressSelection = this.searchForm.get('addressterm')?.value;
    const addressData = this.parseAddressTerm(addressSelection?.display);
    this.address = this.addressList[0].addressLine1;
    const isDifferent = this.api_service.setAddress(this.addressList[0].addressLine1);
    const notEqulaValues = [
      ...this.typeDetails().filter((types) => !this.initialFormValue.includes(types)),
    ];
    let queryParam = {
      ...addressData,
      'search-items': notEqulaValues.map((type) => type.name?.toLowerCase()),
      type: this.searchForm.value.pickup,
    };

    if (isDifferent) {
      queryParam = {
        ...addressData,
        'search-items': this.typeDetails().map((type) => type.name?.toLowerCase()),
        type: this.searchForm.value.pickup,
      };
      // Proceed with your search logic here
    } else if (!notEqulaValues.length) {
      this.openToastrShow('Search results already present.');
      this.isLoading = false;
      return;
      // Handle the case where the address is the same as before
    }
    this.initialFormValue = this.typeDetails();
    const searchValue: { [x: string]: { [key: string]: { Generated_Response: string; }; }[]; } = {};
    this.feedBackStatus = {};
    this.searchForm.value.searchTerm.forEach((key: { name: string; }) => {
      if (Object.keys(this.transformedData.searchResults)
        .some((keyName) => keyName === key.name.toLowerCase())) {
        searchValue[key.name.toLowerCase()] = this.transformedData
          .searchResults[key.name.toLowerCase()];
      }
    });
    return { queryParam, searchValue, addressData };
  }

  searchList(): void {
    const { queryParam, searchValue, addressData } = this.prepareDataForSearch();
    this.api_service.getItemList(queryParam).pipe(
      takeUntil(this.unsubscribeAll),
    ).subscribe({
      next: (data: any) => {
        if (data) {
          this.isLoading = false;
          const res = data as unknown as RawData;
          this.api_service.transformData(res).subscribe((transformed) => {
            Object.keys(transformed.searchResults).forEach((key) => {
              if (transformed.searchResults[key.toLowerCase()]) {
                searchValue[key] = transformed.searchResults[key];
              } else {
                console.log('fail');
              }
            });
            this.transformedData = { searchResults: searchValue };
            this.llmReqId = res?.llm_data?.req_id || '';
            this.createdOn = res?.llm_data?.created_at || '';
          });
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.initialFormValue = [];
        if (addressData == null) {
          this.openToastrShow('Invalid or incomplete address');
        } else {
          this.openToastrShow(error?.error.message);
        }
      },
    });
  }

  searchExtraPickup(): void {
    const { queryParam, searchValue, addressData } = this.prepareDataForSearch();
    this.extraPickupService.getItemList(queryParam).pipe(
      takeUntil(this.unsubscribeAll),
    ).subscribe((data) => {
      if (data) {
        this.isLoading = false;
        const res = data as unknown as RawData;
        this.api_service.transformData(res).subscribe((transformed) => {
          Object.keys(transformed.searchResults).forEach((key) => {
            if (transformed.searchResults[key.toLowerCase()]) {
              searchValue[key] = transformed.searchResults[key];
            } else {
              console.log('fail');
            }
          });

          Object.keys(searchValue).forEach((searchItemKey) => {
            const searchItem = searchValue[searchItemKey];

            searchItem.sort((first: object, second: object) => {
              if ('is_extra_pickup_available' in first) return -1;
              if ('is_extra_pickup_available' in second) return 1;
              return 0;
            });
          });

          this.transformedData = { searchResults: searchValue };
          this.llmReqId = res?.llm_data?.req_id || '';
          this.createdOn = res?.llm_data?.created_at || '';
        });
      }
    }, (error) => {
      this.isLoading = false;
      this.initialFormValue = [];
      if (addressData == null) {
        this.openToastrShow('Invalid or incomplete address');
      } else {
        this.openToastrShow(error?.error.message);
      }
    });
  }

  feedBack(value: string, item: string): void {
    const itemName = item;
    this.feedBackStatus[item] = { isFeedBack: '' };
    const queryParam = {
      feedback: value,
      req_id: this.llmReqId,
      created_at: this.createdOn,
      item: itemName,
    };

    this.api_service.setFeedback(queryParam).pipe(
      takeUntil(this.unsubscribeAll),
    ).subscribe(((res) => {
      if (res) {
        this.feedBackStatus[item] = { isFeedBack: value === 'true' ? 'true' : 'false' };
      }
    }));
  }

  clearSearch(): void {
    this.searchForm.patchValue({
      searchTerm: '',
    });
    this.disabledItems.fill(false);
    this.typeDetails.update(() => []);
    this.initialFormValue = [];
    this.transformedData.searchResults = {};
    console.log(this.transformedData);
  }

  // eslint-disable-next-line class-methods-use-this
  filterResults(): string[] {
    return this.items;
  }

  add(event: MatChipInputEvent): void {
    // Convert the items in the array to lowercase
    const lowerCaseItems = this.selectionOption() === 'bulk-pickup'
      ? this.items.map((item) => item.toLowerCase())
      : this.extraPickupItems.map((item) => item.toLowerCase());

    // Convert the event.value to lowercase
    const lowerCaseValue = event.value.toLowerCase();

    // Find the index of the lowercase value in the lowercase items array
    const index = lowerCaseItems.indexOf(lowerCaseValue);

    if (index !== -1) {
      // Disable the item if it exists
      this.disabledItems[index] = true;
    }
    const value = (event.value).trim();
    const old = this.typeDetails();
    // Check if the item already exists based on the `name` property
    const ex = old.some((val) => val.name?.toLocaleLowerCase() === event.value.toLocaleLowerCase());
    if (!ex && value) {
      this.typeDetails.update((type) => [...type, { name: value }]);
      setTimeout(() => {
        this.searchForm.patchValue({
          searchTerm: [],
        });
        this.searchForm.patchValue({
          searchTerm: this.typeDetails(),
        });
        if (this.typeDetails().length > 5) {
          this.remove(this.typeDetails()[0]);
        }
      }, 3000);
    } else if (value !== '' || ex) {
      this.openToastrShow('Item you are trying to add already exist');
      return;
    }

    const input = event.input as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  remove(type: FormData): void {
    const lowerCaseItems = this.selectionOption() === 'bulk-pickup'
      ? this.items.map((item) => item.toLowerCase())
      : this.extraPickupItems.map((item) => item.toLowerCase());

    const indexes = lowerCaseItems.findIndex(
      (existing) => existing === type.name?.toLocaleLowerCase(),
    );

    // Check if the item exists
    if (indexes !== -1) {
      // Update the disabled state to false for the item
      this.disabledItems[indexes] = false;
    }
    this.typeDetails.update((types) => {
      const index = types.indexOf(type);
      if (index < 0) {
        return types;
      }

      types.splice(index, 1);
      this.announcer.announce(`Removed ${type}`);
      return [...types];
    });
    if (!this.typeDetails().length) {
      this.searchForm.patchValue({
        searchTerm: '',
      });
    }
  }

  edit(type: FormData, value: string) {
    // Edit existing type
    // Convert the items in the array to lowercase
    const lowerCaseItems = this.items.map((item) => item.toLowerCase());

    // Convert the event.value to lowercase
    const lowerCaseValue = value.toLowerCase();

    // Find the index of the lowercase value in the lowercase items array
    const index = lowerCaseItems.indexOf(lowerCaseValue);

    if (index !== -1) {
      // Disable the item if it exists
      this.disabledItems[index] = true;
    }
    const values = (value).trim();
    console.log(values);
    const old = this.typeDetails();

    // Check if the item already exists based on the `name` property
    const ex = old.some((val) => val.name?.toLocaleLowerCase() === value.toLocaleLowerCase());
    if (!ex && value) {
      this.typeDetails.update((types) => {
        const indexes = types.indexOf(type);
        if (indexes >= 0) {
          const typess = types as FormData[];
          typess[index].name = value;
          return [...typess];
        }
        return types;
      });
    } else if (value !== '' || ex) {
      this.openToastrShow('Item you are trying to add already exist');
    }
  }

  clearAddress() {
    this.searchForm.patchValue({
      addressterm: '',
    });
  }

  getItemNames(): string[] {
    return Object.keys(this.transformedData.searchResults || {});
  }

  getFieldKeys(resultItem: ParallelResultItem) {
    return Object.keys(resultItem);
  }

  getAcceptanceStatus(response: string): string {
    this.dummyProperty = 'test';
    // const parts = response.split('.');
    // const lastPart = parts[parts.length - 1].trim(); // Get the last part
    const index = response.indexOf('.');
    const beforeFullStop = index !== -1 ? response.substring(0, index) : response;
    if (beforeFullStop.trim().toLowerCase() === 'acceptable') {
      return 'Acceptable';
    }
    if (beforeFullStop.trim().toLowerCase() === 'not-acceptable'
      || beforeFullStop.trim().toLowerCase() === 'not acceptable') {
      return 'Not Acceptable';
    }
    if (beforeFullStop.trim() !== 'not-acceptable'
      && beforeFullStop.trim() !== 'acceptable' && beforeFullStop.trim() !== 'not acceptable') {
      console.log(beforeFullStop.trim().toLowerCase());
      return 'Conditional';
    }
    return ''; // Return an empty string or a default message if neither
  }

  getBeforeLastComma(input: string) {
    this.dummyProperty = 'test';
    // Find the index of the last comma
    const lastCommaIndex = input.lastIndexOf('.');
    // If there is no comma, return the original input trimmed
    if (lastCommaIndex === -1) {
      return input.trim();
    }
    // Extract and return the substring before the last comma, trimmed
    return input.substring(0, lastCommaIndex + 1).trim();
  }

  getDisplayName(key: string): string {
    this.dummyProperty = 'test';
    const displayNames: { [key: string]: string } = {
      'size/weight': 'Size/Weight Restrictions',
      service_limit: 'Service Limit',
      service_frequency: 'Service Frequency',
      schedule_advance: 'Advance Scheduling',
      prepayment: 'Pre-payment',
      preparation: 'Preparation',
      'charges/fees': 'Rates',
      charge_codes: 'Rates',
      service_level_waste_stream: 'Included Service',
      is_extra_pickup_available: 'Extra Pickup Available',
    };

    return displayNames[key] || key; // Return the mapped name or the original key if not found
  }

  isErrorRoute(): boolean {
    return this.router.url === '/error';
  }

  preventTyping(event: KeyboardEvent) {
    event.preventDefault();
  }
}
